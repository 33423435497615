<template>
    <AddEditForm :headerTitle="$t('common.withdrawal')" :onGoBack="onGoBack" :showDelete="false">
        <template v-slot:headerAddEdit>
            <button type="button" name="add-withdrawal" class="btn btn-primary" @click="onSave">
                {{ $t('pages.withdrawal.setcommand') }}
            </button>           
        </template>
        <template #content>
            <Accordion :headerTitle="$t('common.generalInfo')">
                <template v-slot:body>
                    <CForm>
                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label"><b>{{ $t('pages.withdrawal.availableBalance') }}</b></label>                           
                            <label v-if="walletInfo != null"><b>{{ showAvailableBalance }}</b></label>
                        </div>                        
                       
                        <NumberInput v-model="pointsEnter" :label="$t('pages.withdrawal.enterThePoints')" :onKeyUp="onKeyupPoint">
                            <template v-slot:headerRight>
                                <small class="requiredInput">(*)</small>
                            </template>
                        </NumberInput>

                         <CurrencyInput v-model="amount" :label="$t('common.amount')" :onKeyUp="onKeyup" :isReadonly="true">
                            <template v-slot:headerRight>
                                <small class="requiredInput">(*)</small>
                            </template>
                        </CurrencyInput>

                        <CInput v-model="fee" required horizontal readonly="readonly">
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('pages.withdrawal.withdrawalfee') }}
                                    <small class="requiredInput"> (1 %)</small>
                                </div>
                            </template>
                        </CInput>

                        <CInput v-model="displayTotalAmount" required horizontal readonly="readonly">
                            <template #label>
                                <div class="col-sm-3 col-form-label">
                                    {{ $t('pages.withdrawal.totalamountwithdrawn') }}
                                </div>
                            </template>
                        </CInput>

                        <div role="group" class="form-group form-row">
                            <label class="col-sm-3 col-form-label">{{ $t('pages.warehouse.bankaccount') }}</label>
                            <div class="col-sm-9 input-group">
                                <select class="form-control" v-model="bankAccountId">
                                    <option v-for="(item) in bankAccountList" v-bind:key="item.id" v-bind:value="item.id">
                                        {{ item.accountNo + " - " + item.bankCode }}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </CForm>
                </template>
            </Accordion>           
        </template>
    </AddEditForm>
</template>

<script>
    import { mapGetters } from 'vuex'
    import i18n from '@/plugins/i18n'  
    import CurrencyInput from '@/components/CurrencyInput.vue'
    import NumberInput from '@/components/NumberInput.vue'
    import Accordion from '@/components/Accordion.vue'
    import AddEditForm from '@/components/AddEditForm.vue'
    
    export default {
        name: 'Withdrawal',
		data() {            
			return {                
                horizontal: { label: 'col-3', input: 'col-9' },               
                bankName: '',
                accountNo: '',
                bankAccountId: 0,
                amount: 0,
                fee: 0.01,
                totalAmount: 0,
                pointsEnter: 0
            };
		},
        components: {                     
            CurrencyInput,
            Accordion, AddEditForm, NumberInput
        },
        computed: {           
            ...mapGetters('withdrawal', ['bankAccountList']),
            ...mapGetters('account', ['walletInfo']),

            displayTotalAmount: {
                get: function () {
                    let _val = this.totalAmount;
                    if (_val === undefined)
                        _val = 0;
                    
                    return this.totalAmount.toLocaleString('en-US');
                },
                set: function (modifiedValue) {
                   
                    let newValue = parseFloat(modifiedValue.replace(/[^\d]/g, ""));
                    
                    if (isNaN(newValue)) {
                        newValue = 0
                    }
                    
                    this.$emit('input', newValue);
                }
            },
            showAvailableBalance: {
                get: function () {
                    if (this.walletInfo != null && this.walletInfo.rewardPoints > 0)
                        return this.$func.formatNumber(this.walletInfo.rewardPoints)
                            + ' ' + i18n.t('common.points')
                            + ' = ' + this.$func.formatCurrency(this.walletInfo.rewardPoints * 1000);
                    
                    return '0';
                }
            }
        },
        methods: {
            
            onGoBack() {
                this.$router.push('/users/profile');                
            },
            onKeyup() {
                if (this.amount > 0) {
                    this.totalAmount = this.amount - Math.floor(this.amount * this.fee);
                    this.pointsEnter = this.amount / 1000;
                }
                else {
                    this.totalAmount = 0;
                    this.pointsEnter = 0;
                }
            },
            onKeyupPoint() {
                let _totalPoint = this.pointsEnter;
                if (isNaN(_totalPoint))
                    _totalPoint = 0;

                if (_totalPoint > 0) {
                    this.amount = _totalPoint * 1000;
                    this.totalAmount = this.amount - Math.floor(this.amount * this.fee)
                }
                else {
                    this.pointsEnter = 0;
                    this.totalAmount = 0;
                    this.amount = 0
                }
            },
            async onSave() {

                if (this.walletInfo.rewardPoints <= 0) {
                    this.$store.dispatch('alert/error', this.$t('pages.withdrawal.warningNotEnoughPointToSell'), { root: true });
                    return;
                }
                
                if (this.amount <= 0) {
                    this.$store.dispatch('alert/error', this.$t('pages.deposit.entertheamount'), { root: true });
                    return;
                }
                
                if (this.pointsEnter < 100 || (this.pointsEnter % 100) > 0) {
                    this.$store.dispatch('alert/error', this.$t('pages.withdrawal.warningMinimumPointToSell'), { root: true });
                    return;
                }
                              
                if (this.walletInfo.rewardPoints < (this.totalAmount / 1000)) {
                    this.$store.dispatch('alert/error', this.$t('pages.withdrawal.warningNotEnoughPointToSell'), { root: true });
                    return;
                }

                if (this.bankAccountId <= 0) {

                    this.$store.dispatch('alert/error', this.$t('pages.bank.accountNo'), { root: true });
                    return;
                }

                // begin upload
                var retVal = await this.$store.dispatch("withdrawal/addWithdrawalOrder", {
                    bankAccountId: this.bankAccountId,
                    amount: this.amount,
                    fee: this.fee,
                });
                
                if (retVal.success == true) {                   
                    this.$store.dispatch('toast/displayNotification', { text: i18n.t('pages.withdrawal.noticeWithdrawalOrder') });

                    this.$router.push('/history/withdrawalhistory');
                }
                else {
                    let message = i18n.t('common.unsuccessfull');
                    if (retVal.errorCode != '' && retVal.errorCode != 'Error') {

                        switch (retVal.errorCode) {                            
                            case 'noticeNoVerifyAccount': message = i18n.t('pages.account.noticeNoVerifyAccount'); break;
                            case 'warningInvaliBankAcount': message = i18n.t('pages.account.warningInvaliBankAcount'); break;
                            default:
                                message = i18n.t('pages.withdrawal.' + retVal.errorCode); break;
                        }                      
                    }
                    this.$store.dispatch('alert/error', message);
                }
            },
            async onLoadWalletInfo() {
                var aa = await this.$store.dispatch("account/getWalletInfo", this.$store.state.auth.user.sub);
            }
		},       
        
        created() {
            this.$store.dispatch("withdrawal/loadBankAccountListByUser");

            // set selected default
            if (this.bankAccountList != null && this.bankAccountList.length > 0) {
                this.bankAccountId = this.bankAccountList[0].id;
            }
           
            this.onLoadWalletInfo();
        }
	}
</script>

